<template>
  <div class="result-listing__item lowest-price position-relative">
    <div class="price-tag-top" v-show="indexCheck == 0">
      {{ $t("lowest_price") }}
    </div>
    <div class="result-listing__item__block overflow-hidden">
      <div class="result-listing__item__block-top">
        <div class="row g-0">
          <div class="col-lg-10 col-md-12 left-col">
            <div class="result-listing__item__block_left h-100">
              <div class="result-listing__item__block_top">
                <div
                  class="result-listing__item__block_top_row d-flex flex-wrap align-items-center"
                >
                  <div class="partner-logo">
                    <img
                      :src="`${domain}${url}/img/${compare.image}`"
                      class="img-fluid"
                    />
                  </div>

                  <div class="partner-content">
                    <div class="partner-content_title">
                      <h5 class="mb-0">
                        {{ compare.policy_name }}
                        <!-- <span>(Silver)</span> -->
                      </h5>
                    </div>
                    <div class="partner-content_bottom d-flex">
                      <div class="partner-content_bottom_item">
                        <p>
                          {{ $t("own_damage") }}
                          <span> {{ compare.countries.toString() }} </span>
                        </p>
                      </div>
                      <div class="partner-content_bottom_item">
                        <p>
                          {{ $t("third_party_limit") }}
                          <span v-if="compare.third_party_damage_limit"
                            >{{
                              $filters.currency(
                                compare.third_party_damage_limit
                              ) || 0
                            }}
                          </span>
                          <span v-else
                            >{{
                              $filters.currency(compare.third_party_limit) || 0
                            }}
                          </span>
                        </p>
                      </div>
                      <div
                        class="partner-content_bottom_item"
                        v-show="isDetails"
                      >
                        <p>
                          {{ $t("excess_amount") }}

                          <span>
                            {{ $filters.currency(compare.excess_amount) || 0 }}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="result-listing__item__block_medium">
                <div class="row gx-5">
                  <div class="col-md-4 first-col">
                    <div class="listing-ul">
                      <ul>
                        <li
                          :class="
                            compare.personal_accident_driver ? 'check' : 'cross'
                          "
                        >
                          {{ $t("personal_accident_driver") }}
                          <span v-if="compare.personal_accident_driver"
                            >{{ $t("limit_up_to") }}
                            {{ compare.personal_accident_driver }}</span
                          >
                        </li>
                        <li
                          :class="
                            compare.personal_accident_passenger &&
                            compare.personal_accident_passenger > 0
                              ? 'check'
                              : 'cross'
                          "
                        >
                          {{ $t("personal_accident_passenger")
                          }}<span v-if="compare.personal_accident_passenger > 0"
                            >{{ $t("limit_up_to") }}
                            {{ compare.personal_accident_passenger }}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-md-4 second-col">
                    <div class="listing-ul">
                      <ul>
                        <li class="check">
                          {{ $t("personal_accident_family") }}
                        </li>
                        <li
                          v-if="
                            compare.roadside_assistance &&
                            compare.roadside_assistance.length > 0
                          "
                          :class="
                            compare.roadside_assistance &&
                            compare.roadside_assistance.length > 0
                              ? 'check'
                              : 'cross'
                          "
                        >
                          {{ $t("roadside_assistance") }}
                        </li>
                        <li
                          :class="
                            compare.agency_repair == 1 ? 'check' : 'cross'
                          "
                        >
                          {{ $t("agency_repair") }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-md-4 third-col">
                    <div class="checkbox-listing">
                      <div
                        v-if="compare.personal_accident_driver"
                        class="checkbox-item"
                      >
                        <input
                          class="styled"
                          id="styled-checkbox-2"
                          type="checkbox"
                          v-model="compare.value_personal_driver"
                          @change="addValueDriver(compare)"
                        />
                        <p for="styled">
                          <span
                            >{{ $t("personal_accident_driver") }}
                            <strong>
                              {{
                                $filters.currency(
                                  compare.personal_accident_driver
                                )
                              }}</strong
                            ></span
                          >
                        </p>
                      </div>

                      <div
                        v-if="
                          compare.personal_accident_passenger &&
                          compare.personal_accident_passenger > 0
                        "
                        class="checkbox-item"
                      >
                        <input
                          class="styled"
                          id="styled-checkbox-2"
                          type="checkbox"
                          v-model="compare.value_personal_passenger"
                          @change="addValuePassenger(compare)"
                        />
                        <p for="styled">
                          <span
                            >{{ $t("personal_accident_passenger") }}
                            <strong>
                              {{
                                $filters.currency(
                                  compare.personal_accident_passenger
                                )
                              }}</strong
                            ></span
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-12 right-col">
            <div class="right-buy-now text-center">
              <div class="right-buy-now-top">
                <div class="premium-title">
                  <p>{{ $t("total_premium") }}</p>
                </div>
                <div class="price-title">
                  <h4 class="mb-0">
                    {{ $filters.currency(compare.policy_price) }}
                  </h4>
                </div>
                <div class="buy-now-button">
                  <el-button
                    @click.prevent="BuyNow(compare)"
                    class="btn buy-now"
                    >{{ $t("buy_now") }}</el-button
                  >
                </div>
                <div class="plan-details">
                  <a
                    v-if="compare.terms_url"
                    :href="`${domain}${url}/tc/${compare.terms_url}`"
                    target="_blank"
                    >{{ $t("plan_details") }}
                    <span class="icon-chevron-right"></span>
                  </a>
                </div>
              </div>
              <div class="right-buy-now-bottom">
                <div class="custom-checkbox">
                  <el-checkbox :label="compare">{{
                    $t("add_to_compare")
                  }}</el-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- need to add statement -->
      <div
        class="result-listing__item__block-bottom"
        :class="compare.value_read_more ? 'open' : ''"
      >
        <div class="result-listing__item__block-bottom-top">
          <div class="row justify-content-lg-between">
            <div class="col-lg-3 col-md-4 highlights">
              <div class="listing-title">
                <h6 class="mb-0">{{ $t("highlights") }}</h6>
              </div>
              <div class="listing-ul">
                <ul>
                  <li
                    v-for="(
                      policy, index
                    ) in compare.car_policy_highlight_title"
                    :key="index"
                    :class="policy.is_available ? 'check' : 'cross'"
                  >
                    {{ policy.highlight }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 road-assistant">
              <div class="listing-title">
                <h6 class="mb-0">{{ $t("roadside_assistance") }}</h6>
              </div>
              <div class="listing-ul">
                <ul>
                  <li
                    v-for="(roadside, index) in compare.roadside_assistance"
                    :key="index"
                    :class="roadside.is_available ? 'check' : 'cross'"
                  >
                    {{ roadside.title }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 extra-add-col">
              <div class="listing-title">
                <h6 class="mb-0">{{ $t("extra_addon") }}</h6>
              </div>

              <div
                class="extra-addons d-flex align-items-center"
                v-for="(addon, index) in compare.addons"
                :key="index"
              >
                <div class="addons-checkbox">
                  <input
                    id="replacement"
                    type="checkbox"
                    v-model="addon.addonCheck"
                    @change="addonAdd(addon, compare)"
                  />
                  <label> </label>
                </div>
                <div class="addons-text">
                  <div>
                    <span style="line-height: 1.5rem; overflow: hidden">{{
                      addon.add_on
                    }}</span>
                    <span>
                      <strong v-if="addon.addonValue && addon.addonCheck">
                        {{ $filters.currency(addon.addonValue.price) }}
                      </strong>
                      <select
                        v-model="addon.addonValue"
                        v-if="addon.addonCheck"
                        @change="changeAddon(addon, compare)"
                      >
                        <option
                          class="vehicle"
                          v-for="(item, index) in addon.addon_attr"
                          :key="index"
                          :label="item.details"
                          :value="item"
                        />
                      </select>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 special-feactures">
              <div class="listing-title">
                <h6 class="mb-0">{{ $t("special_feature") }}</h6>
              </div>
              <div class="listing-p">
                <div class="desc-p" v-html="compare.special_features"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="result-listing__item__block-footer">
          <p>{{ $t("tc") }}</p>
        </div>
      </div>
    </div>
    <div class="more-details text-center">
      <button
        class="btn"
        @click="compare.value_read_more = !compare.value_read_more"
        v-show="isDetails"
      >
        <i> {{ !compare.value_read_more ? "More Details" : "Less Details" }}</i>
        <span class="icon-dropdown-down"></span>
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import { useStore } from "@/store";
import Buy from "@/core/services/car/BuyNow";
import router from "@/router";
import { useReCaptcha } from "vue-recaptcha-v3";

export default defineComponent({
  props: {
    compare: {
      required: true,
      type: Object,
    },
    indexCheck: {
      required: true,
      type: Number,
    },
    isDetails: {
      required: true,
      type: Boolean,
    },
  },

  setup() {
    const store = useStore();
    const captcha = useReCaptcha();

    const domain = ref(process.env.VUE_APP_ASSETS_DOMAIN);
    const url = ref(process.env.VUE_APP_INSURANCE_ASSETS_URL);
    const price = ref(0);

    // computed
    const compareData = computed(() => {
      return store.state.qoutes.compareData;
    });

    const addValueDriver = (val) => {
      console.log(val);
      if (val.value_personal_driver) {
        val.policy_price += val.personal_accident_driver;
      } else {
        val.policy_price -= val.personal_accident_driver;
      }
    };
    const addValuePassenger = (val) => {
      if (val.value_personal_passenger) {
        val.policy_price += val.personal_accident_passenger;
      } else {
        val.policy_price -= val.personal_accident_passenger;
      }
    };

    const addonAdd = (val, original) => {
      if (val.addonCheck) {
        original.policy_price += val.addonValue.price;
        val.prev_price = val.addonValue.price;
      } else {
        original.policy_price -= val.prev_price;
      }
    };

    // change event
    const changeAddon = (val, original) => {
      original.policy_price += val.addonValue.price;
      original.policy_price -= val.prev_price;
      val.prev_price = val.addonValue.price;
    };

    const checkDisable = computed(() => {
      return store.state.qoutes.isDisable;
    });

    const dataCompare = ref < Array < Object >> [];

    // handleClick
    const BuyNow = async (val) => {
      // load captcha
      await captcha?.recaptchaLoaded();
      // execute reCaptcha
      const token = await captcha?.executeRecaptcha("login");
      // console.log(token);

      const date = new Date();
      const expiryDate = date.setDate(date.getDate() + 30);
      const dateExpiry = new Date(expiryDate).toISOString();
      const data = {
        value: val,
        uuid_dd: window.localStorage.getItem("fall_back_dd"),
        uuid_vd: window.localStorage.getItem("fall_back"),
        payment_type: 1,
        order_description: "Online Generated Quotation",
        is_amend: 0,
        policy_sales_agent_id: 1,
        original_price: val.policy_price,
        policy_price: val.policy_price,
        sale_price: val.policy_price,
        language: "en",
        is_agency_repair: val.agency_repair || 2,
        bai_car_insurance_policy_id: val.id || val.car_insurance_id,
        expiry_date_time: dateExpiry.split("T")[0] + " 23:59:59",
        manufacturer_id: JSON.parse(
          window.localStorage.getItem("customer_detatils")
        ).manufacturer_id,
        model_id: JSON.parse(window.localStorage.getItem("customer_detatils"))
          .model_id,
        trim_level_id: JSON.parse(
          window.localStorage.getItem("customer_detatils")
        ).trim_level_id,
      };
      if (token) {
        Buy.getQoutationReference(data);
        router.push({ name: "SecureChecout" });
      }
    };

    return {
      price,
      captcha,
      compareData,
      dataCompare,
      checkDisable,
      addValueDriver,
      addValuePassenger,
      BuyNow,
      addonAdd,
      changeAddon,
      domain,
      url,
    };
  },
});
</script>

<style lang="scss" scoped>
.checkbox-listing .checkbox-item .styled-checkbox-2 {
  position: absolute;
  opacity: 1;
  z-index: 99;
  width: 15px;
  height: 15px;
}

.upDown-enter-from {
  opacity: 0;
}

.upDown-enter-to {
  opacity: 1;
}

.upDown-enter-active {
  transition: all 2s ease;
}

.btn {
  display: inline-block;
}

.checkbox-item {
  display: inline-flex;
  align-items: center;

  p {
    margin-left: 5px;
  }
}

ul li {
  height: 20px;
  white-space: nowrap;

  span {
    margin-top: 15px;
  }
}

@media (min-width: 768px) {
  .result-listing__item .price-tag-top {
    position: absolute;
    transform: rotate(-90deg);
    transform-origin: bottom left;
    top: 115px;
    left: 30px;
  }

  .result-listing__item__block-bottom.open {
    display: block;
    height: 100%;
    // opacity: 1;
  }
}

@media (max-width: 600px) {
  .result-listing__item__block-bottom.open {
    display: block;
    height: 100%;
    // opacity: 1;
  }
}

.result-listing__item .price-tag-top {
  background: #0061ff;
  color: #fff;
  font-size: 15.5px;
  padding: 15px 18px 15px;
  display: inline-flex;
  text-transform: uppercase;
  font-weight: bold;
}

.result-listing__item__block-bottom {
  display: block;
  height: 0;
  //   opacity: 0;
  transition: height 0.4s;
  -webkit-transition: height 0.4s;
  -moz-transition: height 0.4s;
}

.result-listing__item__block
  .result-listing__item__block-bottom-top
  .extra-addons
  .addons-text {
  flex: 1;
  font-size: 13px;
  color: #222261;
  margin-left: 1.2rem;
}

.result-listing__item__block
  .result-listing__item__block-bottom-top
  .extra-addons
  .addons-text
  div
  span
  strong {
  margin-right: 1.5rem;
  flex: 100%;
  margin-top: 1.5rem;
}

.result-listing__item__block
  .result-listing__item__block-bottom-top
  .extra-addons
  .addons-text
  div
  span
  select {
  margin-top: 1.5rem;
}

.result-listing__item__block {
  transition: 0.3s all ease;
  background-color: #fff;
  box-shadow: 0px 3px 3px 1px rgb(216 226 241 / 25%);
  border-radius: 9px;
  border: 2px solid transparent;
}
</style>
